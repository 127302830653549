import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { navigateToUrl } from 'single-spa'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { AppBar, Box, Grid, InputLabel } from '@mui/material'

import { User, api } from '@shared/api'
import {
  Button,
  ButtonBar,
  ButtonBarEnd,
  HasPermission,
  Loading,
  PageLayoutContainer,
  PageLayoutContainerMain,
  UserDetailsForm,
  buildUserDetailsSchema,
} from '@shared/components'
import { useFtpPortalHubCommunication } from '@shared/hooks'
import { processPhone, toArrayFieldErrors } from '@shared/utils'

const useStyles = tss.withName('UserManagementEdit').create(({ theme }) => ({
  cancelButtonContainer: {
    width: '101px',
  },
  saveButtonContainer: {
    marginLeft: '8px',
    width: '86px',
  },
}))

export default function UserManagementEdit() {
  const { id } = useParams()
  const { classes } = useStyles()
  const { state } = useLocation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { t } = useTranslation()
  const userSchema = buildUserDetailsSchema(t)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const portal = sessionStorage.getItem('portal')
  const backUrl = state?.backUrl || `/${portal}/user-management/${id}/view`

  const methods = useForm<yup.InferType<typeof userSchema>>({
    resolver: yupResolver(userSchema),
  })

  const getUser = async (userId) => {
    try {
      setIsLoading(true)
      const user = await api.service('users').get(userId)

      setAppBarTitle(`${user.first_name || ''} ${user.last_name}`, undefined, [
        t('common.users-management'),
        t('common.edit-user'),
      ])

      methods.reset({
        first_name: user?.first_name || undefined,
        last_name: user?.last_name,
        email: user?.email,
        cell_phone: user?.cell_phone || undefined,
        user_type_code: user?.user_type_code,
        tz: user?.tz,
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!id) return

    getUser(id)
  }, [id])

  const onSubmit = async (data: Partial<User>) => {
    try {
      setIsLoadingSubmit(true)

      const payload = {
        ...data,
        first_name: data.first_name || null,
        cell_phone: data.cell_phone ? processPhone(data.cell_phone) : null,
      }

      await api.service('users').patch(id, payload)

      navigateToUrl(`/${portal}/user-management/${id}/view`)
    } catch (error) {
      const errorsFormatted = toArrayFieldErrors(error)

      errorsFormatted.forEach((e) => {
        // API needs to fix mapping for error on this field, should return as 'user_type_code'
        if (e.field === 'user_type_id') {
          e.field = 'user_type_code'
        }
        methods.setError(e.field, {
          type: 'manual',
          message: e.message,
        })
      })
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <HasPermission allPermissions={['v2.users.put']}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PageLayoutContainer>
            <PageLayoutContainerMain isFullWidth>
              <UserDetailsForm guidingId="usermanagement-edit" />
            </PageLayoutContainerMain>

            <AppBar
              sx={{
                bottom: 0,
                top: 'auto',
                position: 'fixed',
                boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
              }}
            >
              <ButtonBar style={{ marginBottom: '0 !important' }}>
                <ButtonBarEnd>
                  <Button
                    label={t('common.cancel')}
                    color="secondary"
                    containerClassName={classes.cancelButtonContainer}
                    testId="cancel-button"
                    onClick={() => navigateToUrl(backUrl)}
                    guidingId="usermanagement-edit-cancel"
                  />
                  <Button
                    type="submit"
                    label={t('common.save')}
                    isLoading={isLoadingSubmit}
                    containerClassName={classes.saveButtonContainer}
                    testId="save-button"
                    guidingId="usermanagement-edit-save"
                  />
                </ButtonBarEnd>
              </ButtonBar>
            </AppBar>
          </PageLayoutContainer>
        </form>
      </FormProvider>
    </HasPermission>
  )
}
