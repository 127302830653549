import React, { useState, forwardRef } from 'react'

import SvgIcon from '@mui/material/SvgIcon'

import { InputProps, Input } from '@shared/components'

const EyeOff = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 19.5604C11.158 19.5604 10.315 19.3824 9.496 19.0654"
          stroke="#9CA3AF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.882 13.0284C18.99 16.5274 15.495 19.5604 12 19.5604"
          stroke="#9CA3AF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.079 9.48138C19.77 10.2904 20.384 11.1724 20.882 12.0934C21.039 12.3844 21.039 12.7374 20.882 13.0284"
          stroke="#9CA3AF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 19.5604L19 5.56036"
          stroke="#9CA3AF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.773 14.7874C8.543 13.5574 8.543 11.5624 9.773 10.3324C11.003 9.10235 12.998 9.10235 14.228 10.3324"
          stroke="#9CA3AF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.044 7.51636C15.497 6.31936 13.748 5.56036 12 5.56036C8.505 5.56036 5.01 8.59336 3.118 12.0934C2.961 12.3844 2.961 12.7374 3.118 13.0284C4.064 14.7774 5.41 16.4094 6.956 17.6054"
          stroke="#9CA3AF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

const EyeOn = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.11799 13.0274C2.96099 12.7364 2.96099 12.3834 3.11799 12.0924C5.00999 8.59336 8.50499 5.56036 12 5.56036C15.495 5.56036 18.99 8.59336 20.882 12.0934C21.039 12.3844 21.039 12.7374 20.882 13.0284C18.99 16.5274 15.495 19.5604 12 19.5604C8.50499 19.5604 5.00999 16.5274 3.11799 13.0274Z"
          stroke="#9CA3AF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.1213 10.439C15.2929 11.6106 15.2929 13.5101 14.1213 14.6817C12.9497 15.8533 11.0502 15.8533 9.87868 14.6817C8.70711 13.5101 8.70711 11.6106 9.87868 10.439C11.0502 9.26747 12.9497 9.26747 14.1213 10.439"
          stroke="#9CA3AF"
          strokeWidth="1.4286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

const InputPassword = forwardRef<unknown, InputProps>(
  (
    {
      label,
      value,
      placeholder,
      onChange,
      onBlur,
      error = false,
      helperText,
      required = false,
      style,
      testId,
      guidingId,
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(false)

    return (
      <Input
        ref={ref}
        type={showPassword ? 'text' : 'password'}
        label={label}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
        icon={{
          component: showPassword ? (
            <EyeOn color="disabled" fontSize="medium" />
          ) : (
            <EyeOff color="disabled" fontSize="medium" />
          ),
          onClick: () => setShowPassword(!showPassword),
        }}
        required={required}
        style={style}
        testId={testId}
        guidingId={guidingId}
      />
    )
  }
)

export default InputPassword
