import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { User } from '@shared/api'
import { ChangePassword, FieldGroupContainer } from '@shared/components'
import { useAuthorization, useEnforceLogin } from '@shared/hooks'
import { UserStatusEnum } from '@shared/types'
import { checkPermission } from '@shared/utils'

export function Security({ user }: { user: User }) {
  const { t } = useTranslation()
  const { user: userLogged } = useEnforceLogin()
  const { userPermissionSet } = useAuthorization()
  const [resetModalOpen, setResetModalOpen] = useState(false)

  const canResetPassword =
    user.status_code !== UserStatusEnum.PendingInvitation &&
    userLogged?.user_type_code >= user.user_type_code &&
    userLogged?.id !== user.id &&
    checkPermission(userPermissionSet, 'v2.users.post')

  return (
    <>
      <FieldGroupContainer
        title={t('user-management.security')}
        buttonData={
          canResetPassword
            ? {
                label: t('common.password-reset'),
                onClick: () => setResetModalOpen(true),
                color: 'secondary',
                guidingId: 'usermanagement-view-passwordreset',
              }
            : null
        }
      >
        <Typography>
          {t('user-management.user-will-be-required-to-set-their-own-password')}
        </Typography>
      </FieldGroupContainer>
      <ChangePassword
        user={user}
        open={resetModalOpen}
        handleClose={() => setResetModalOpen(false)}
        resetForOtherUser={true}
      />
    </>
  )
}
