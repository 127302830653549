import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Typography } from '@mui/material'

import { User, api } from '@shared/api'
import { ActionModal } from '@shared/components'
import { useNotification } from '@shared/hooks'
import { UserStatusEnum } from '@shared/types'
import { toArrayFieldErrors } from '@shared/utils'

const useStyles = tss.withName('ToggleCustomerActivationModal').create(() => ({
  buttonContainer: {
    width: '40%',
  },
}))

export interface UserActivationModalProps {
  user: User
  open: boolean
  onClose?: () => void | Promise<void>
  onSuccess?: () => void | Promise<void>
}

export const UserActivationModal: React.FC<UserActivationModalProps> = ({
  user,
  open,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { setNotification } = useNotification()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onSubmit = async () => {
    setIsLoading(true)

    try {
      if (user.status_code === UserStatusEnum.Inactive) {
        await api
          .service('users')
          .patch(user.id, { status_code: UserStatusEnum.Active })
      }

      if (user.status_code === UserStatusEnum.Active) {
        await api.service('users').remove(user.id)
      }

      setNotification({
        type: 'success',
        label: `${t('user-management.user-successfully')} ${
          user.status_code === UserStatusEnum.Inactive
            ? t('common.reactivated')
            : t('common.deactivated')
        } `,
      })

      onClose?.()
      onSuccess?.()
    } catch (error) {
      const errors = toArrayFieldErrors(error)
      const messages = errors.map((error) => error.message)

      setNotification({
        type: 'error',
        label: messages.join(' | '),
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <ActionModal
        open={open}
        title={
          user.status_code === UserStatusEnum.Inactive
            ? t('user-management.reactivate-user')
            : t('user-management.deactivate-user')
        }
        buttons={[
          {
            testId: 'cancel-button',
            label: t('common.cancel'),
            containerClassName: classes.buttonContainer,
            color: 'secondary',
            onClick: onClose,
            guidingId: 'usermanagement-activation-cancel',
          },
          {
            testId: 'submit-button',
            type: 'submit',
            label: t('common.yes'),
            containerClassName: classes.buttonContainer,
            isLoading,
            onClick: onSubmit,
            guidingId: 'usermanagement-activation-submit',
          },
        ]}
        onClose={onClose}
        guidingId="usermanagement-activation"
      >
        <Typography sx={{ fontSize: '14px' }} variant="body2">
          {`${t('common.modal.are-you-sure')} ${(user.status_code === 0
            ? t('common.reactivate')
            : t('common.deactivate')
          ).toLocaleLowerCase()} ${t('common.user').toLocaleLowerCase()} `}
          <strong>
            {user.first_name || ''} {user.last_name}
          </strong>
          ?
        </Typography>
      </ActionModal>
    </>
  )
}
