import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { User } from '@shared/api'
import { ActionModal } from '@shared/components'

import { LocationAccess } from '../location-access/LocationAccess'

const useStyles = tss.withName('EditLocationAccessModal').create(() => ({
  container: {
    width: '600px',
  },
  button: {
    width: '100%',
  },
}))

interface EditLocationAccessModalProps {
  user: User
  isOpen: boolean
  onClose: () => void
  guidingId?: string
}

export const EditLocationAccessModal: FC<EditLocationAccessModalProps> = ({
  user,
  isOpen,
  onClose,
  guidingId,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  return (
    <ActionModal
      open={isOpen}
      onClose={onClose}
      title={t('user-management.location-access')}
      buttons={[
        {
          label: t('common.done'),
          onClick: onClose,
          color: 'primary',
          guidingId: `${guidingId}-editlocationaccess-done`,
          containerClassName: classes.button,
        },
      ]}
      className={classes.container}
      guidingId={`${guidingId}-editlocationaccess`}
    >
      <LocationAccess
        user={user}
        isOnModal
        guidingId={`${guidingId}-editlocationaccess`}
      />
    </ActionModal>
  )
}
